<template>
  <v-container>
    <v-card elevation="2">
      <v-card-title>
        <span>资产列表</span>
        <v-spacer />
        <v-text-field
          clearable
          label="输入合约地址"
          append-icon="mdi-magnify"
          v-model="search"
          @click:append="onSearch"
          @keydown.enter="onSearch"
        />
        <div class="select">
          <v-select
            clearable
            label="资产类型"
            v-model="type"
            :items="types"
            @change="onTypeChange"
          />
        </div>
      </v-card-title>
      <v-data-table
        disable-sort
        :headers="table.headers"
        :items="table.items"
        :server-items-length="table.total"
        @update:page="onPagination"
        class="elevation-1"
      >
        <template v-slot:[`item.contract_address`]="{ item }">
          <RippleLink :to="`/address/${item.contract_address}`">
            {{ item.contract_address }}
          </RippleLink>
        </template>
        <template v-slot:[`item.contract_name`]="{ item }">
          {{ item.contract_name || '-' }}
        </template>
        <template v-slot:[`item.contract_symbol`]="{ item }">
          {{ item.contract_symbol || '-' }}
        </template>
        <template v-slot:[`item.expired`]="{ item }">
          {{
            item.contract_type === 'COUPON'
              ? `${YMD(item.contract_expired_start_date)} - ${YMD(
                  item.contract_expired_end_date
                )}`
              : '-'
          }}
        </template>
        <template v-slot:[`item.deployed_at`]="{ item }">
          {{ YMDHms(item.deployed_at) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { RippleLink, Ellipsis } from '@/components';
import { Contract } from '@/api';
import { YMDHms, YMD, valid } from '@/utils';

export default {
  name: 'Addresses',
  components: { RippleLink },
  data: () => ({
    types: [
      { text: '卡券', value: 'COUPON' },
      { text: '积分', value: 'GRC20' },
      { text: '数字文创', value: ['DIGITALIP', 'ARTWORK'] },
    ],
    type: '',
    search: '',
    table: {
      headers: [
        { text: '合约地址', value: 'contract_address' },
        { text: '品牌', value: 'contract_name' },
        { text: '资产名称', value: 'contract_symbol' },
        { text: '有效期', value: 'expired' },
        { text: '部署时间', value: 'deployed_at' },
      ],
      items: [],
      total: 0,
    },
  }),
  created() {
    const that = this;
    const type = that.$route.query.type;
    this.types.find((item) => { if (item.text === type) { that.type = item.value; }});
  },
  mounted() {
    this.getAssets(1);
  },
  methods: {
    YMDHms,
    YMD,
    getAssets(page) {
      Contract.describeContracts(
        10,
        (page - 1) * 10,
        undefined,
        undefined,
        this.type ? (Array.isArray(this.type) ? this.type : [this.type]) : undefined,
        this.search || undefined
      ).then((res) => {
        this.table = {
          ...this.table,
          items: res.contracts,
          total: res.total_count,
        };
      });
    },
    onPagination(page) {
      this.getAssets(page);
    },
    onSearch() {
      const _ = this.search;

      if (valid.isAddress(_)) {
        this.$router.push(`/address/${_}`);
        return;
      }
      this.getAssets(1);
    },
    onTypeChange() {
      this.getAssets(1);
    },
  },
};
</script>

<style lang="scss">
.select {
  width: 12%;
  min-width: 160px;
  margin-left: 24px;
}
</style>
